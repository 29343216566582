.tech-talks-container {
  display: flex;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
}

.content-container {
  display: flex;
  flex-direction: column;
}

.image-subcomponent {
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 100px;
  height: auto;
  width: auto;
}

.image-subcomponent img {
  max-width: 100%;
  max-height: 200px;
}

.body-subcomponent {
  max-width: 400px;
  margin-left: 92px;
  margin-top: 30px;
  letter-spacing: 0.095em;
  word-spacing: 1px;
  line-height: 1.75;
  text-align: left;
}

.header-subcomponent {
  margin-left: 92px;
  text-align: left;
}
.learnMoreWrapper {
  border: solid 1px black;
  width: 30%;
  border-radius: 5px 0px 0px 5px;
  padding: 0px 0px 0px 20px;
  text-decoration: none;
  color: #000000;
  padding: 10px 15px;
}
.learnMoreWrapper:hover {
  background-color: #000000;
  color: #ffffff;
}
.arrowDirection {
  display: inline;
  text-align: center;
  border: solid 1px black;
  border-left: 0;
  border-radius: 0px 5px 5px 0px;
  font-size: 1.5em;
  padding: 2.5px 15px 8px;
}

.image-container {
  /* CSS properties and values */
  /* position: relative;
  margin: -600px 0px 0px calc((100% - 980px) * 0.5);
  left: 400px;
  grid-area: 1 / 1 / 2 / 2;
  justify-self: start;
  align-self: start; */
}
