.footer {
    background-color: #f2f2f2;
    padding: 20px;
    text-align: center;
  }
  
  .social-links {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .social-icon {
    color: #555;
    font-size: 24px;
    margin: 0 10px;
  }
  
  .footer-text {
    color: #888;
  }
  