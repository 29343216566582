video {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.videoBox{
    position: absolute;
    width: 100%;
    height: 130%;
    top:0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: aliceblue;
    /* left:25%; */
}
