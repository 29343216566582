body {
    font-family: Arial, sans-serif;
    /* margin: 20px; */
    /* background-color: #ffffff46; */
  }
  
  h2 {
    color: #333;
    text-align: center;
  }
  
  form {
    background-color: #e6b0b0;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 600px;
    margin: 0 auto;
  }
  
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }

  
  input[type="text"],
  input[type="email"],
  input[type="url"],
  input[type="file"],
  textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding-right: 0px;
  }
  
  input[type="radio"] {
    margin-right: 5px;
  }
  
  #constructor_info,
  #other_info {
    display: none;
  }
  
  input[type="submit"] {
    background-color: #0b0c0c;
    width: 100%;
    height: 3.2em;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
  }
  
  input[type="submit"]:hover {
    background-color: #181919;
  }
  .required:after {
    content: " *";
    color: red;
  }
  