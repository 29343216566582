.partners-section {
  background-color: black;
  text-align: left;
}

.content-container-partners {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden
}

.text-container-partners {
  margin-left: 20px;
  width: 40%;
  padding: 40px;
  text-align: justify;
}

.image-container-partners {
  width: 50%;
  margin-right: 150px;
}

.partnersimage {
  width: 100%; 
  height: auto;
  opacity: 0;
  transform: scale(1); 
  transition: opacity 1.0s ease-in-out, transform 1.5s ease-in-out;
}
.partnersimage.active {
  opacity: 1;
}
.partnersimage:hover {
  transform: scale(1.5); 
  transition: transform 0.5s ease-in-out;
}

.partners-section h1 {
  color: ghostwhite;
  font-size: 14px;
  margin-top: -80px; /* space above the first line of text */
  margin-bottom: 100px;
  letter-spacing: 2px;
  line-height: 12px;
  font-weight: normal;
}

.partners-section p {
  color: ghostwhite;
  width: 330px;
  font-size: 15px;
  letter-spacing: 1px;
  line-height: 25px;
  font-weight: normal;
}

/* vertical line with moving short line */
.line-partners {
  position: relative;
  width: 1px;
  height: 350px;
  border-right: 1.5px solid #fff;
  background-color: rgba(0, 0, 0, 0.8);
  margin-top: -40px;
  margin-left: 90px;
}

.moving-element-partners {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(100px);
  width: 4px;
  height: 80px;
  border-right: 3px solid #fff;
  animation: move-partners 1.5s 1 linear forwards;
}

@keyframes move-partners {
  0% {
    transform: translateX(-50%) translateY(120px);
  }
  100% {
    transform: translateX(-50%) translateY(40px);
  }
}
