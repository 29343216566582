.About {
  background-color: #000;
  color: #fff;
  padding: 0 0 0 60px;
  position: relative;
}

/* .AboutText {
  position: relative;
} */

.VerticalLine {
  width: 1px;
  border-left: 1px solid #fff;
  height: 60%;
  background-color: #000000;
  position: absolute;
  top: 90px;
  left: 2%;
}

.ShortLine {
  width: 1px;
  border-left: 3px solid #ffffff;
  height: 20%;
  position: absolute;
  top: 200px;
  left: 2%;
  transition: transform 1.2s ease-in-out;
}

.ShortLine.active {
  transform: translateY(-50px);
}

.AboutTextWrapper {
  /* margin: 0 0 0 40px; */
  /* width: 100%; */
  padding-right: 35px; 
  text-align: justify;
  background-color: #000;
}

.AboutTextWrapper p {
  color: ghostwhite;
  /* width: 450px; */
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 25px;
  font-weight: normal;
}

.About img {
  max-width: 100%;
  height: 100%;
  border-radius: 10px;
  /* box-shadow: 0 0 10px rgba(255, 4, 4, 0.5); */
  transition: box-shadow 1.3s ease, transform 0.7s ease;
}

.About img:hover {
  box-shadow: 0px 0px 100px rgba(255, 3, 3, 0.5);
  transform: scale(1.03);
}

/* .HackconinNumber{
  background-color: #d6bdbd4d;
} */

.HackconInNumbersWrapper h4 {
  margin: 130px 0 40px 0;
  font-size: 24px;
  letter-spacing: 2px;
  line-height: 43px;
  font-weight: normal;
}
.HackconInNumbersWrapper{
  /* margin: 100px 50px; */
  /* width: 50%; */
  margin: auto;
}

.numbersgrid {
  position: relative;
  
  margin: 40px 0px 0px 25px;
  /* margin: 0px 0px 0px 0px; */
}

.stats {
  margin: 40px 0px 0px 25px;
  position: relative;
}

.statnumbers {
  padding: 10px 0;
  font-size: 40px;
  font-weight: normal;
  letter-spacing: 3px;
  line-height: 33px;
}

.numbersText {
  padding: 10px 0;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 2px;
  line-height: 12px;
}

.HackconInNumbersWrapper {
  text-align: left;
}

.HorizontalLine {
  /* height: 1px; */
  width: 60%;
  border-bottom: 1px solid #ff0000;
  background-color: #ff0000;
  /* position: absolute; */
  /* top: 90px;
      left: 10%; */
}

.ShortHorizontalLine {
  height: 3px;
  width: 10%;
  position: absolute;
  left: 150px;
  border-top: 3px solid #000000;
  /*top: 200px; 
    /* left: 10%; */
  /* top: 0;
      left: 0; */
  transition: transform 1.5s ease-in-out;
}

.ShortHorizontalLine.active {
  transform: translateX(-126px);
}

.container {
  display: flex;
}

.container1 {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: black;
  background-size: cover;
}

.header1 {
  float: left;
  padding: 20px;
  position: relative;
  color: white;
  border-bottom: 2px solid white;
}

.header {
  color: black;
  width: 100%;
}

.header h2 {
  text-align: left;
  font-size: 24px;
  letter-spacing: 2px;
  line-height: 20px;
  font-weight: normal;
}

.textdiv {
  display: flex;
  width: 50%;
  flex-direction: row;
}

.textdiv p {
  font-size: 16px;
  letter-spacing: 1px;
  line-height: 14px;
  font-weight: normal;
}

.textdiv1 {
  display: inline-block;
  width: 25%;
  flex-direction: column;
  margin: 80px;
}

.textdiv1 p {
  color: white;
}

.button {
  background-color: transparent;
  color: white;
  border: 2px solid #555555;
  padding: 12px 28px;
  border-radius: 8px;
  font-size: 20px;
}

.button:hover {
  background-color: white;
  color: black;
}

.line {
  height: 5px;
  width: 400px;
  background-color: var(--brd);
  transition: transform 0.3s ease;
  transform: translateX(0%);
  opacity: var(--alpha-brd);
  transform-origin: center 0.5px;
  border-bottom: 2px solid black;
}
/* Your existing CSS styles */

.logoForHackCon {
  opacity: 0;
  transform: translateX(-50px); 
  transition: opacity 1.5s ease, transform 1.5s ease; 
}

.logoForHackCon.active {
  opacity: 1;
  transform: translateX(0); 
}

@media (max-width: 767px) {
  .HackconInNumbersWrapper{
    padding: 50px;
  }
}

