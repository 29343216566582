.FAQ-section {
  padding: 100px 30px;
}

.faq-question {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
  background-color: #f5f5f5;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 10px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.FAQ-section h1 {
  font-size: 36px;
  margin-bottom: 50px;
}

.faq-question:hover,
.faq-question.active {
  background-color: #f05151;
  color: #f5f5f5;
}

.faq-question.active {
  /* background-color: #f37c7c; */
}

.faq-question::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  transform: translateY(-50%) rotate(225deg);
  width: 12px;
  height: 12px;
  border-left: 2px solid #333;
  border-bottom: 2px solid #333;
  transition: transform 0.3s ease;
}

.faq-question:hover::after,
.faq-question.active::after {
  border-left: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
}

.faq-question.active::after {
  transform: translateY(-50%) rotate(315deg);
}

.faq-answer {
  background-color: #f05151;
  padding: 16px;
  border-radius: 8px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 550;
  line-height: 1.4;
  color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
