.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  height: 50px;
  transition: 1s;
  min-height: 20px;
  color: white;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sticky {
  /* background-color: rgb(30, 91, 46, 95%); */
  background-color: #1B1716;
  transition: 1s;
  /* border-radius: 0 0 40px 40px; */
}

.navbar-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar-container a {
  text-decoration: none;
  padding: 10px 20px;
  color: #8D98A7;
  font-size: 18px;
  transition: 0.5s;

  font-family: 'loveleaves';
}

.navbar-container a:hover {
  color: #F3F7FB;
  transition: 0.5s;

}


/* .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  font-size: 16px;
  letter-spacing: 0;
  height: 50px;
  top: 0;
  right: 0;
  left: 0;
  padding: 5px 30px;
  z-index: 1;
  background-color: transparent;
  border: none;
  outline: none;
  transition: all 0.3s ease;
  color: white;
} */
.sticky {
  transition: all 1.3s ease;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 6vh; /* Set the desired shorter height when sticky */
  background-color: black; /* Set the black background color */
  color: white; /* Set the white text color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Optional: Add a shadow effect */
  /* background-image: linear-gradient(to bottom, rgba(242, 242, 242, 0.9), rgba(0, 0, 0, 0)); */
}

.logo a{
  /* hackcon */
  /* font-family: 'Handjet', cursive; */
  /* font-family: 'Inter', sans-serif; */
  /* font-family: 'Kanit', sans-serif; */
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'PT Serif', serif;
  /* font-family: 'Slackside One', cursive;; */
  font-size: 30px;
  letter-spacing: 7px;
  line-height: 17px;
  font-weight: bold;
  color: red;
  margin-left: 40px;
  text-decoration: none;
}

.nav-links {
  cursor: pointer;
  list-style: none;
  display: flex;
  margin-right: 20px;
}

.nav-links li {
  margin-left: 35px;
}

.nav-links li a {
  text-decoration: none;
}

.nav-links li a:hover {
  text-decoration: underline; /* Add underline on hover if desired */
}

.MLHlogo {
  display: inline-block;
  margin-left: 0px; /* Move the logo to the rightmost position */
  margin-right: 0px; /* Add some space between the logo and the last link */
  max-height: 40px;
  margin-top: -20px;
}
