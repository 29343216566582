@media screen and (max-width: 980px) {
  .team-section {
    /* display: none; */
  }
}
.team-section {
  padding: 100px 50px;
}

.text-container-team {
  margin-bottom: 40px;
  font-weight: bold;
}
.text-container-team h1 {
  color: black;
  font-size: 36px;
  letter-spacing: 0;
  line-height: 47px;
  font-weight: bold;
}

.team-member {
  margin-bottom: 40px;
  position: relative;
  margin: 50px 20px;
}

.team-member img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  transition: transform 0.7s ease;
}

.name-overlay {
  position: absolute;
  bottom: 10;
  left: 0;
  width: 100%;
  padding: 10px;
  /* background-color: rgba(210, 48, 48, 0.8); */
  color: rgb(0, 0, 0);
  text-align: center;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
}

.name-overlay h1 {
  margin: 0;
  font-size: 18px;
}

.name-overlay p {
  margin: 0;
  font-size: 14px;
}

.image-container-team {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.team-member:hover img {
  transform: scale(1.1);
}

.team-member:hover .name-overlay {
  opacity: 0.8;
  background-color: rgb(233, 46, 46);
  color: black;
}
