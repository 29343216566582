.box {
  width: 490px;
  height: 664px;
  background-color: #000000;
  position: relative;
  margin: 67px 0px 10px calc((100% - 980px) * 0.5);
  left: 100px;
  top: 100px;
  grid-area: 1 / 1 / 2 / 2;
  justify-self: start;
  align-self: start;
  border-radius: 16px;
  transition: transform 0.7s ease, box-shadow 1.3s ease;
  padding-left: 20px;
  padding-right: 40px;
}
.box:hover {
  /* box-shadow: 0 0 20px rgba(255, 0, 0, 1.4); */
  transform: translateY(-10px);
}
.box.active {
  /* transform: translateY(-100px); */
}

.header-text {
  color: rgb(255, 255, 255);
  letter-spacing: 0.16em;
  font: normal normal normal 14px/1.4em montserrat, sans-serif;
  /* overflow-wrap: break-word; */
  text-align: start;
  position: absolute;
  margin: 56px 0px 42px 0;
  left: 92px;
  grid-area: 1 / 1 / 2 / 2;
  justify-self: start;
  align-self: start;
}

.title-text {
  color: rgb(255, 255, 255);
  letter-spacing: 0.08em;
  font: normal normal normal 24px/1.4em montserrat, sans-serif;
  position: absolute;
  margin: 125px 0px 10px 0;
  left: 92px;
  grid-area: 2 / 1 / 3 / 2;
  justify-self: start;
  align-self: start;
  word-break: break-word;
  text-align: left;
  width: calc(100% - 92px);
}

.body-text {
  color: white;
  letter-spacing: 0.05em;
  font: normal normal normal 16px/1.7em montserrat, sans-serif;
  position: absolute;
  margin: 0;
  top: 300px; /* Adjust the top positioning as needed */
  left: 92px;
  grid-area: 3 / 1 / 4 / 2;
  justify-self: start;
  align-self: start;
  word-break: break-word;
  text-align: left;
  width: calc(100% - 92px);
  padding: 0px 100px 100px 0px;
}
/* .container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    height: 100vh;
} */

.learn-more-box {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 8px;
}

.learn-more {
  color: #ffffff;
  background-color: #000000;
  border-radius: 5px;
  font: normal normal normal 16px/1.4em montserrat, sans-serif;
  letter-spacing: 0.1em;
  text-decoration: none;
  display: inline-block;
  position: absolute;
  text-align: center;
  top: 550px;
  left: 92px;
  border: solid white;
  width: 150px;
}

.arrow {
  position: absolute;
  top: 50%;
  right: -12px;
  transform: translateY(-50%);
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  padding: 3px;
  pointer-events: none;
  transition: all 0.3s ease;
}

.learn-more:hover .arrow {
  transform: translateY(-50%) rotate(90deg);
}

.parallax {
  background-image: url("../../../assets/PreviousCompPics/oldpic4.jpg");
  width: 100%;

  height: 900px;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
