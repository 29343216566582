.WhattoExpect,
.WhoCanParticpate {
  padding: 80px 40px;
  text-align: justify;
}
.WhattoExpectHeader,
.WhoCanParticpateHeader {
  font-size: x-large;
  margin-bottom: 10px;
}
.WhattoExpectWrapper,
.WhoCanParticpateWrapper {
  padding: 40px;
}

.WhoCanParticpate {
  background-color: black;
  color: white;
}
.apply-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 16px;
  transition: color 0.3s ease;
}

.apply-button:hover {
  color: black;
}

.arrow-left {
  position: absolute;
  left: 8px;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid white;
}

.applyWrapper {
  border: solid 1px rgb(253, 253, 253);
  width: 30%;
  border-radius: 5px 0px 0px 5px;
  padding: 0px 0px 0px 20px;
  text-decoration: none;
  color: #ffffff;
  padding: 10px 15px;
}
.applyWrapper:hover {
  background-color: #fa0000;
  color: #000000;
  font-weight: 500;
}
.applyarrowDirection {
  display: inline;
  text-align: center;
  border: solid 1px rgb(255, 255, 255);
  border-left: 0;
  border-radius: 0px 5px 5px 0px;
  font-size: 1.5em;
  padding: 2px 15px 8px;
}
