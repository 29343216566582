body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', */
    /* monospace; */
}
body {
  font-family: "Montserrat", sans-serif;
  font-family: Arial;
  margin: auto;
  background-color: #d6bdbd46;
  max-width: 1500px;
  min-width: 400px;
  text-align: center;
}